<template>
    <footer>
        <div class="footer-content">
            <p>Copyright © 2023 CEDI : Centre for Entrepreneurship Development and Incubation, NIT Trichy. All Rights
                Reserved</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'CopyrightFooter'
}
</script>


<style scoped>
footer {
    /*position: fixed;*/
    left: 0;
    bottom: 0;
    width: 100%;

    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: center;
}

.footer-content {
    max-width: 850px;
    margin: 0 auto;
}

h3 {
    font-size: 1.5em;
}

p {
    font-size: 1em;
    line-height: 1.5;
}

body {
    font-family: Arial, Helvetica, sans-serif;
}
</style>