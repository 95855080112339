<template>
    <div class="row" style="margin:0px;margin-top:10px;margin-bottom:10px;">
        <div class="col-6 col-md-6">
            <div class="image_container">
                <img :src="nitt_cedi" class="img-fluid" @click="handleImageClick" @mousemove="handleMouseMove"
                    @mouseleave="handleMouseLeave" ref="imageRef" :class="hoverClass">
            </div>
        </div>
        <div class="col-2 col-md-2">
            <div class="image_container">
                <a href="https://g20.mygov.in/" target="_blank">
                    <img :src="g20" style="width:100%;max-width:100px;padding:5px;">
                </a>
            </div>
        </div>

        <div class="col-2 col-md-2">
            <div class="image_container">
                <a href="#">
                    <span class="d-none d-md-inline" style="text-decoration:none;color:black;">
                        CSR
                    </span>
                    <img :src="csr" style="width:90%;max-width:40px;padding:5px;">
                </a>
            </div>
        </div>
        <div class="col-2 col-md-2">
            <div class="image_container">
                <router-link to="/Donate">
                    <span class="d-none d-md-inline" style="text-decoration:none;color:black;">
                        Give to CEDI
                    </span>
                    <img :src="donate" style="width:90%;max-width:40px;padding:5px;">
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import main_img from '@/assets/nitt_cedi.png';
import second_img from '@/assets/g20-logo.png';
import give_img from '@/assets/icons8-give-96.png';
import responsiblity_img from '@/assets/icons8-responsibility-64.png';
export default {
    data() {
        return {
            nitt_cedi: main_img,
            g20: second_img,
            donate: give_img,
            csr: responsiblity_img,
            hoverClass: ''
        }
    },
    methods: {
        handleImageClick(e) {
            let x = e.offsetX;
            // Get the width of the image
            const imageWidth = this.$refs.imageRef.clientWidth;
            const clickPercentage = (x / imageWidth) * 100;
            if (clickPercentage <= 17) {
                console.log('Clicked on NITT');
                window.open(
                    "https://www.nitt.edu/",
                    "_blank");
            } else if (clickPercentage >= 84) {
                console.log('Clicked on CEDI');
                window.location.href = "/";
            } else {
                //console.log('Clicked on a different area of the image.');
            }
        },
        handleMouseMove(event) {
            const x = event.offsetX;
            const imageWidth = this.$refs.imageRef.clientWidth;
            const mousePercentage = (x / imageWidth) * 100;
            if (mousePercentage <= 17 || mousePercentage >= 84) {
                this.hoverClass = 'hand-cursor';
            } else {
                this.hoverClass = '';
            }
        },
        handleMouseLeave() {
            this.hoverClass = '';
        },
    }
};
</script>
<style scoped>
.image_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.hand-cursor {
    cursor: pointer;
    /* Set the cursor type to a hand pointer */
}
</style>
